import { AUTH_TOKEN } from '@/vue-apollo'
import http from 'ky'
export const parseUrl = process.env.VUE_APP_PARSE_SERVER || `http://localhost:3000`

export const cloudFunction = async (
  functionName: string,
  body = {},
  options = {}
): Promise<any> =>
  http.post(`${parseUrl}/parse/functions/${functionName}`, {
    headers: {
      'X-Parse-Application-Id': process.env.VUE_APP_PARSE_APP_ID,
      'X-Parse-REST-API-Key': process.env.VUE_APP_PARSE_JS_KEY,
      'X-Parse-Session-Token': localStorage.getItem(AUTH_TOKEN) || ''
    },
    json: {
      ...body
    },
    ...options
  }).json()
